#form {
  width: 200px;
  display: flex;
  align-items: center;
  position: absolute;
  gap: 10px;
  z-index: 999;
  margin-top: 90px;
  margin-left: 15px;
}

#form > svg {
  transform: scale(1.2);
  transform-origin: center;
}

#searchInput {
  width: 90%;
  font-size: 16px;
  line-height: 1.8rem;
  background-color: #519c901a;
  border: 1px solid #db005b;
  border-radius: 10px;
  padding-left: 10px;
}

#searchInput::placeholder {
  color: #67ffd9aa;
}
