.game__item {
  background-color: #044d4145;
  border: 2px solid #db005b;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  padding: 10px;
  transition: all 0.3s ease;
}

.game__item:hover {
  background-color: #044d41;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.game__item > a {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.img__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(138, 61, 61, 0.596);
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.game__img {
  padding: 5px;
  overflow: hidden;
  width: 170px;
  height: auto;
}

.content__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
}

.game__title {
  font-size: 20px;
  text-align: start;
  text-transform: capitalize;
  text-shadow: 1px 1px 1px #db005b, -1px -1px 1px #00a0d2;
}

.line__through {
  width: 80%;
  text-align: end;
  padding-bottom: 5px;
  margin-left: 35px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #67ffd9;
  position: relative;
}

.line__through::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;

  /* Adjust the height as needed */
  background: linear-gradient(90deg, #67ffd9a3 50%, #db005b9c 50%);
}

.about__game {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}
